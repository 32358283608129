import { get } from 'aws-amplify/api';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

import MenuBar from './MenuBar';

Amplify.configure(amplifyconfig);

export default function PromoLanding() {
    const { hash } = useParams("hash");
    const [promoCode, setPromoCode] = useState({});
    const [attemptedGet, setAttemptedGet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getPromoCode = async () => {
            // "missing authentication token", and, smashing away at it in infinite loop
            const restOperation = get({
                apiName: "stripePromo",
                path: "/promo",
                options: {
                    queryParams: {
                        hash: hash,
                    }
                },
            });
            setLoading(true);
            try {
                const { body } = await restOperation.response;
                const promoCodeObj = await body.json();
                setPromoCode(promoCodeObj);
                setLoaded(true);
            } finally {
                setLoading(false);
                setAttemptedGet(true);
            }
        };
        if (!attemptedGet) getPromoCode();
    });
    if (promoCode.error) console.error(promoCode.error);
    const code = promoCode.code;
    const discountStr = promoCode.percentOff ? `${promoCode.percentOff}%` : `$${promoCode.amountOff}`;
    let durationStr;
    if (promoCode.duration === "once") {
        durationStr = "first year of membership";
    } else if (promoCode.duration === "forever") {
        durationStr = "membership, ongoing,";
    } else if (promoCode.duration === "repeating") {
        const durationInYears = parseInt(promoCode.durationInMonths) / 12;
        durationStr = `first ${durationInYears} years of membership`;
    }
    return (
        <Container>
            <MenuBar />
            <Container>
                {loading ? (
                    <Loader active inline='centered' />
                ) : (
                    <br />
                )}
                {loaded ? (
                    <Container>
                        <p>
                            Welcome!  You've been offered a discount GPC Australia membership!
                        </p>
                        <p>
                            Use promo code <strong>{code}</strong> for {discountStr} off the cost of your {durationStr}{" "}
                            when you sign up at the "Join GPC" link in the menu bar. 
                        </p>
                    </Container>
                ) : (
                    <br />
                )}
            </Container>
        </Container>
    );
}